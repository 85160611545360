/** @jsx jsx */
import { jsx, Box, Grid, Heading } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

const CustomersPage = () => {
  let { allPrismicCustomers } = useStaticQuery(graphql`
    query customersQuery {
      allPrismicCustomers {
        nodes {
          data {
            images {
              image {
                url
              }
              customer {
                text
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Box variant="layout.wrapper.large">
        <Heading as="h1">Customers</Heading>
        <Box>
          {allPrismicCustomers.nodes.map(({ data }, index) => {
            // Image = props => <Img fluid={alp.data.alp_image.fluid} {...props} />
            return (
              <Grid columns={[2, 3, 4]} key={index} sx={{ gap: 4 }}>
                {data.images.map(({ customer, image }, index) => (
                  <Link key={index} target="_blank" to={customer[0].text}>
                    <img src={image.url} />
                  </Link>
                ))}
              </Grid>
            )
          })}
        </Box>
      </Box>
    </Layout>
  )
}

export default CustomersPage
